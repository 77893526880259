import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'

import useEmblaCarousel from 'embla-carousel-react'
import SanityImage from 'gatsby-plugin-sanity-image-alt'
import { DotButton } from './Buttons'
import { useSanityImageShape } from '../../helpers/useSanityImageShape'
import '../../styles/embla.css'
import Icon from '../../svg/icon-cross.svg'

const Carousel = ({ images, isMobile }) => {
  const language = process.env.GATSBY_SITE_LANGUAGE
  let imageWidth = 768
  let imageHeight = 600

  const [fullScreen, setFullScreen] = useState(false)

  const [viewportRef, embla] = useEmblaCarousel()
  // const [prevBtnEnabled, setPrevBtnEnabled] = useState(false)
  // const [nextBtnEnabled, setNextBtnEnabled] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  // const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla])
  // const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla])
  const scrollTo = useCallback(index => embla && embla.scrollTo(index), [embla])

  const onSelect = useCallback(() => {
    if (!embla) return
    setSelectedIndex(embla.selectedScrollSnap())
    // setPrevBtnEnabled(embla.canScrollPrev())
    // setNextBtnEnabled(embla.canScrollNext())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, setScrollSnaps, onSelect])

  useEffect(() => {
    if (fullScreen) {
      imageWidth = 1366
      imageHeight = 760
    }
  }, [fullScreen])

  return (
    <>
      <motion.div
        className={`${
          fullScreen ? 'w-screen h-screen fixed z-100' : 'h-full w-full'
        }`}
      >
        <div
          className={`embla ${fullScreen ? 'bg-white' : ''}`}
          onClick={() => setFullScreen(true)}
        >
          <div className="embla__viewport" ref={viewportRef}>
            <div
              className={`embla__container ${
                fullScreen ? 'flex items-center' : ''
              }`}
            >
              {images &&
                images.map((image, index) => {
                  
                  const imageShaped = useSanityImageShape(image)

                  return (
                    <div className="embla__slide" key={index}>
                      <div className="embla__slide__inner">
                        <SanityImage
                          {...imageShaped}
                          className={`pointer-events-none ${
                            fullScreen
                              ? 'object-contain w-full'
                              : 'embla__slide__img  object-cover h-full'
                          }`}
                          width={fullScreen ? image.asset.width : imageWidth}
                          height={fullScreen ? image.asset.height : imageHeight}
                          alt={image.alt}
                        />
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        </div>
        {/* <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
        <NextButton onClick={scrollNext} enabled={nextBtnEnabled} /> */}
        <div className="embla__dots">
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              selected={index === selectedIndex}
              onClick={() => scrollTo(index)}
            />
          ))}
        </div>
        {isMobile && !fullScreen && <p className="absolute bottom-[8px] w-full text-center font-bold text-sm">{language !== 'it' ? 'Click to enlarge' : 'clicca per ingrandire'}</p>}
        {fullScreen && (
          <button
            type="button"
            className="absolute top-0 mt-16 right-0 flex items-center p-4 leading-none text-gray-200 z-500"
            onClick={() => setFullScreen(false)}
          >
            <span className="mr-2 pointer-events-none mt-3px font-sm">
              Close
            </span>
            <Icon className="pointer-events-none" width="18" height="18" />
          </button>
        )}
      </motion.div>
    </>
  )
}

Carousel.propTypes = {
  images: PropTypes.array,
}

Carousel.defaultProps = {
  images: {},
}

export default Carousel
